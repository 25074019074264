* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

ol, ul, li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.navbar {
  z-index: 2;
  width: 100%;
  max-width: 1920px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.navbar:hover {
  background-color: #29343ce6 !important;
}

.nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  height: 84px;
  margin: 0 auto;
  display: flex;
}

.nav-icon {
  margin-right: 80px;
}

.nav .nav-icon img {
  width: 122px;
  height: 32px;
}

.nav-ul {
  color: #fff;
  justify-content: space-between;
  gap: 100px;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nav-ul .nav-item {
  cursor: pointer;
}

.nav-ul .underline {
  transform: all .3s ease;
  background-color: #3498db;
  border-radius: 2px;
  width: 100px;
  height: 2px;
  position: absolute;
  top: 28px;
  left: -100px;
}

.nav .nav-left {
  align-items: center;
  display: flex;
}

.nav .nav-right {
  color: #fff;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  display: flex;
}

.nav .nav-right > div:first-child {
  margin-right: 30px;
}

.bottom {
  color: #000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  display: flex;
}

.home_page {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  overflow-x: hidden;
}

.b_title {
  text-align: center;
  margin: 50px 0;

  & .title {
    color: #c7c7c7;
    font-size: 34px;
    font-weight: 700;
  }

  & .subtitle {
    font-size: 24px;
  }

  & .f, & .ssubtitle {
    margin-top: 6px;
  }
}
/*# sourceMappingURL=ciprip.057b83e0.css.map */
